import Link from 'next/link'
import { useRouter } from 'next/router'

import { NavTag } from './NavTag'

interface IHeaderNavProps {
  isAd: boolean
}

const HeaderNav = ({ isAd }: IHeaderNavProps) => {
  const router = useRouter()

  const navList = [
    {
      title: '부트캠프',
      href: `${isAd ? '/school/advertisement_1' : '/school'}`,
      tag: <NavTag name="국비지원" />,
    },
    {
      title: '6주완성',
      href: '/onboarding',
      tag: <NavTag name="전액환급" />,
    },
    {
      title: '이벤트',
      href: '/event',
    },
    {
      title: '기업 문의',
      href: '/enterprise',
    },
  ]

  return (
    <nav>
      <ul className="hidden lg:flex">
        {navList.map(({ title, href, tag }, idx) => {
          const pathName = router.pathname.split('/')[1]
          const hrefName = href.split('/')[1]
          const isFirst = idx === 0
          const isDivider = idx === 2
          return (
            <li key={`${title}_${href}`} className="flex flex-row items-center">
              <hr
                className={`h-5 w-[1px] border-l ${isFirst ? 'hidden' : 'flex'} ${
                  isDivider ? 'mx-2.5 border-gray-200' : 'border-none'
                }`}
              />
              <Link href={href} as={href}>
                <div
                  className={`relative flex cursor-pointer space-x-1.5  py-5 font-semibold ${
                    pathName === hrefName ? 'text-yellow-450' : ''
                  } ${tag ? 'px-3' : 'px-4'}`}>
                  <span>{title}</span>
                  {tag && <>{tag}</>}
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default HeaderNav
